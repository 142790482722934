import React, { useState } from "react";
import logoImage from "./logo.png";
import JSZip from "jszip";

export default function IconGenerator() {
  const [imageUrl, setImageUrl] = useState("");
  const [generatedIcons, setGeneratedIcons] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const generateIcons = async () => {
    if (!imageUrl) {
      alert("Please upload an image first.");
      return;
    }

    setLoading(true);

    try {
      const iconSizes = [
        { name: "apple-touch-icon.png", width: 180, height: 180 },
        { name: "favicon.ico", width: 32, height: 32 },
        { name: "icon512.png", width: 512, height: 512 },
        { name: "icon192.png", width: 192, height: 192 },
        { name: "favicon.png", width: 64, height: 64 },
        { name: "icon-round-32x32.png", width: 32, height: 32 },
        { name: "icon-round-64x64.png", width: 64, height: 64 },
        { name: "icon-round-196x196.png", width: 196, height: 196 },
      ];

      const icons = await Promise.all(
        iconSizes.map(async (icon) => {
          const canvas = document.createElement("canvas");
          canvas.width = icon.width;
          canvas.height = icon.height;
          const ctx = canvas.getContext("2d");

          const image = new Image();
          image.crossOrigin = "anonymous";
          image.src = imageUrl;
          await new Promise((resolve) => {
            image.onload = resolve;
          });
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          return {
            name: icon.name,
            dataUrl: canvas.toDataURL("image/png"),
            width: icon.width,
            height: icon.height,
          };
        }),
      );

      setGeneratedIcons(icons);
    } catch (error) {
      console.error("Error generating icons:", error);
      alert("Failed to generate icons. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    const textToCopy = `
   <!-- Place in the head tag of HTML -->
   <link rel="shortcut icon" href="favicon.ico" />
   <link rel="icon" type="image/x-icon" sizes="16x16 32x32" href="favicon.ico">
   <link rel="apple-touch-icon" sizes="any" href="apple-touch-icon.png">
   <link rel="icon" sizes="32x32" href="favicon-32.png" >
   <meta name="msapplication-TileColor" content="#FFFFFF">
   <meta name="msapplication-TileImage" content="favicon-144.png">
   <meta name="theme-color" content="#ffffff">
   <link rel="manifest" href="manifest.json">
   <link rel="icon" sizes="192x192" href="favicon-192.png">
   <!-- icons.JesseJesse.com -->
`;

    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(textToCopy);
        alert("Copied to clipboard!");
      } else {
        // Fallback for browsers that do not support clipboard API
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert("icons are ready to link in html");
      }
    } catch (error) {
      console.error("Error copying to clipboard:", error);
      alert("Failed to copy to clipboard. Please try again manually.");
    }
  };

  const downloadIcons = async () => {
    const zip = new JSZip();

    // Add icons to the ZIP file
    generatedIcons.forEach((icon) => {
      zip.file(icon.name, icon.dataUrl.split("base64,")[1], { base64: true });
    });

    // Create index.html content
    let htmlContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Favicons</title>
    <link rel="shortcut icon" href="favicon.ico">
    <link rel="icon" type="image/x-icon" sizes="16x16 32x32" href="favicon.ico">
    <link rel="icon" sizes="192x192" href="favicon-192.png">
    <link rel="apple-touch-icon" sizes="180x180" href="favicon-180-precomposed.png">
    <meta name="msapplication-TileColor" content="#FFFFFF">
    <meta name="msapplication-TileImage" content="favicon-114-precomposed.png">
    
    <style>
        body {
            background-color: #f5f5f5;
            border: 0px;
            margin: 0px;
            padding: 0px;
            font-family: Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier New, monospace, serif;
            color: black;
        }
        
        pre {
            margin: 0px;
            color: black;
            padding: 0px 5%;
        }
        
        code {
            
        }
        
        .container {
            background-color: white;
            max-width: 800px;
            width: 100%;
            margin: 0 auto;
            padding: 1% 0;
            height: 100%;
        }
        
        .comment {
            color: gray;
            padding: 0px;
            margin: 0px;
        }
        
        hr {
            width: 80%;
            padding: 0 5%;
            border-color: #f5f5f5;
            background-color: #D1D1D1;
        }
        
        p {
            padding: 1% 5%;
        }
        
        .copy-button {
            background-color: #4CAF50;
            color: white;
            border: none;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            margin-top: 10px;
            cursor: pointer;
        }
    </style>
</head>
<body>
    <div class="container">
        <hr>
        <center><h2>icons.JesseJesse.com</h2></center>
        <pre>
            <code id="favicon-code">
&lt;link rel=&quot;shortcut icon&quot; href=&quot;favicon.ico&quot; /&gt;
&lt;link rel=&quot;icon&quot; type=&quot;image/x-icon&quot; sizes=&quot;16x16 32x32&quot; href=&quot;favicon.ico&quot;&gt;

<!-- Apple -->
&lt;link rel=&quot;apple-touch-icon&quot; sizes=&quot;any&quot; href=&quot;apple-touch-icon.png&quot;&gt;
&lt;link rel=&quot;icon&quot; sizes=&quot;32x32&quot; href=&quot;favicon-32.png&quot; &gt;

<!-- Android -->
&lt;meta name=&quot;msapplication-TileColor&quot; content=&quot;#FFFFFF&quot;&gt;
&lt;meta name=&quot;msapplication-TileImage&quot; content=&quot;favicon-144.png&quot;&gt;

<!-- Google -->
&lt;meta name=&quot;theme-color&quot; content=&quot;#ffffff&quot;&gt;
&lt;link rel=&quot;manifest&quot; href=&quot;manifest.json&quot;&gt;
&lt;link rel=&quot;icon&quot; sizes=&quot;192x192&quot; href=&quot;favicon-192.png&quot;&gt;
            </code>
        </pre>
        
       <center><button class="copy-button" onclick="copyFaviconCode()">Copy Favicon Tags</button></center>
        
        <hr>
    </div>
    
    <script>
        function copyFaviconCode() {
            // Select the code block containing favicon tags
            var codeBlock = document.getElementById('favicon-code');
            
            // Create a textarea element to hold the text to copy
            var textarea = document.createElement('textarea');
            textarea.value = codeBlock.innerText;
            
            // Make sure it's not visible
            textarea.style.position = 'absolute';
            textarea.style.left = '-9999px';
            
            // Append the textarea to the body
            document.body.appendChild(textarea);
            
            // Select and copy the text inside the textarea
            textarea.select();
            document.execCommand('copy');
            
            // Remove the textarea from the DOM
            document.body.removeChild(textarea);
            
            // Optionally, provide user feedback
            alert('Favicon tags copied to clipboard!');
        }
       </script>
       <center><img src="favicon.ico" hieght="32px" width="32px">favicon</img>&nbsp;&nbsp; <img src="icon-round-64x64.png" hieght="64px" width="64px"></img>64px<br><br><br><img src="favicon.png" hieght="16px" width="16px">16px</img></center><br>
       <center><img src="apple-touch-icon.png" hieght="180px" width="180px">180px</img><img src="apple-touch-icon.png" hieght="196px" width="196px">196px</img></center><br>
   </body>
   </html>
`;

    // Add index.html to the ZIP file
    zip.file("index.html", htmlContent);

    // Generate ZIP file
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Create a download link and trigger download
    const a = document.createElement("a");
    a.href = URL.createObjectURL(zipBlob);
    a.download = "icons.zip";
    a.click();
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center">
      {/* Logo at the top */}
      <img
        src={logoImage}
        alt="Logo"
        className="max-w-full h-auto mb-4"
        style={{ maxHeight: "30vh" }}
      />

      <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
        {/* Input for uploading image */}
        <input
          type="file"
          onChange={(e) => setImageUrl(URL.createObjectURL(e.target.files[0]))}
          className="hidden"
          id="imageUpload"
        />
        <label
          htmlFor="imageUpload"
          className="block w-full mb-4 cursor-pointer"
        >
          <div className="flex justify-center items-center w-48 h-48 border-2 border-dashed border-gray-300 rounded-lg">
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Uploaded"
                className="max-w-full h-auto rounded-lg"
                style={{ maxHeight: "200px" }}
              />
            ) : (
              <div className="text-gray-400 text-center">
                <svg
                  className="w-32 h-32 mb-3 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
                <p className="text-sm leading-normal">add image</p>
              </div>
            )}
          </div>
        </label>

        {/* Generate Icons button */}
        {imageUrl && (
          <div className="flex justify-center mt-4 space-x-4">
            <button
              onClick={generateIcons}
              disabled={isLoading}
              className={`bg-yellow-400 hover:bg-green-400 text-black font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {isLoading ? "Generating..." : "Generate Icons"}
            </button>
          </div>
        )}
      </div>

      {/* Display generated icons */}
      {generatedIcons.length > 0 && (
        <div className="mt-8 grid grid-cols-2 gap-4">
          {generatedIcons.map((icon, index) => (
            <div key={index} className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <img
                src={icon.dataUrl}
                alt={icon.name}
                className="max-w-full h-auto rounded-lg"
                style={{ maxHeight: "150px" }}
              />
              <p className="text-center mt-2 text-sm text-white">{icon.name}</p>
            </div>
          ))}
        </div>
      )}

      {/* Buttons for copying and downloading icons */}
      {generatedIcons.length > 0 && (
        <div className="mt-8 flex justify-center space-x-4">
          <button
            onClick={copyToClipboard}
            className="bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
          >
            Copy html
          </button>
          <button
            onClick={downloadIcons}
            className="bg-violet-500 hover:bg-violet-800 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
          >
            Download icons
          </button>
        </div>
      )}

      {/* Footer */}

      <footer className="text-center text-pink-300 mt-8">
        made with&nbsp;&#10085;&nbsp;
        <a href="https://icons.jessejesse.com/" className="underline-none">
          icons.JesseJesse.com
        </a>
        &nbsp;&copy;2024
        {/* GitHub Button Script */}
        <script async defer src="https://buttons.github.io/buttons.js"></script>
      </footer>
      <br />
      <div id="github-button-container">
        {/* GitHub Button */}
        <a
          className="github-button"
          href="https://github.com/sudo-self/icons"
          data-color-scheme="no-preference: light; light: light; dark: light;"
          data-icon="octicon-star"
          data-size="large"
          aria-label="Star sudo-self/icons on GitHub"
        >
          Star
        </a>
      </div>
    </div>
  );
}
